.footer {
    background-color: #1a5f7a;
    color: white;
    padding: 20px 0;
    margin-top: 40px;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .footer p {
    margin: 0;
  }
  
  .footer-nav a {
    color: white;
    text-decoration: none;
    margin-left: 20px;
  }
  
  @media screen and (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-nav {
      margin-top: 10px;
    }
  
    .footer-nav a {
      display: block;
      margin: 10px 0;
    }
  }