.bsa-calculator-page {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #fff;
}

.hero {
  text-align: center;
  margin-bottom: 40px;
}

.hero-title {
  font-size: 2.5em;
  color: #250902;
  margin-bottom: 10px;
}

.hero-description {
  font-size: 1.2em;
  color: #666;
}

.calculator-section {
  margin-bottom: 40px;
}

.conversion-card {
  background-color: #e3fe55;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.input-container input,
.input-container select {
  flex: 1;
  min-width: 120px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.convert-button {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  color: #fff;
  background-color: #250902;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.convert-button:hover {
  background-color: #3a0e03;
}

.how-to-calculate,
.other-calculators,
.popular-items,
.bsa-chart {
  margin-bottom: 40px;
}

.how-to-calculate h2,
.other-calculators h2,
.popular-items h2,
.bsa-chart h2 {
  font-size: 1.8em;
  color: #250902;
  margin-bottom: 20px;
}

.calculation-example {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
}

.calculation-example h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.calculators-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.calculator-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  background-color: #f0f8ff;
  padding: 15px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.calculator-link:hover {
  background-color: #e6f3ff;
}

.calculator-link img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.popular-items ul {
  list-style-type: none;
  padding: 0;
}

.popular-items li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.bsa-chart table {
  width: 100%;
  border-collapse: collapse;
}

.bsa-chart th,
.bsa-chart td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.bsa-chart th {
  background-color: #250902;
  color: #fff;
}

.bsa-chart tr:nth-child(even) {
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .input-container {
    flex-direction: column;
  }

  .input-container input,
  .input-container select {
    width: 100%;
  }

  .hero-title {
    font-size: 2em;
  }

  .hero-description {
    font-size: 1em;
  }

  .how-to-calculate h2,
  .other-calculators h2,
  .popular-items h2,
  .bsa-chart h2 {
    font-size: 1.5em;
  }

  .calculators-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}