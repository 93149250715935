body {
    background-color: white;
    color: #333;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .home-page {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .hero {
    text-align: left;
    margin-bottom: 40px;
  }
  
  .hero-title {
    font-size: 36px;
    color: #1a5f7a;
    margin-bottom: 10px;
  }
  
  .hero-description {
    font-size: 18px;
    color: #333;
  }
  
  .conversion-card {
    background-color: #e8f3f8;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .input-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .input-container input,
  .input-container select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-container input {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .convert-button {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    background-color: #1a5f7a;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .result-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .result-table th,
  .result-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  .how-to-calculate,
  .other-conversions,
  .bsa-formula,
  .bsa-chart {
    margin-bottom: 40px;
  }
  
  h2 {
    color: #1a5f7a;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  h3 {
    color: #333;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .conversions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .conversion-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333;
    background-color: #f0f8ff;
    padding: 20px;
    border-radius: 8px;
    transition: background-color 0.3s;
  }
  
  .conversion-link:hover {
    background-color: #e6f3ff;
  }
  
  .conversion-link img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .bsa-chart table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .bsa-chart th,
  .bsa-chart td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  @media (max-width: 768px) {
    .input-container {
      flex-direction: column;
    }
  
    .input-container input,
    .input-container select {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .conversions-grid {
      grid-template-columns: 1fr;
    }
  }
  .bsa-formula h3 {
    color: #1a5f7a;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .bsa-formula p {
    margin-left: 20px;
  }
  
  .bsa-chart table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #ccc;
  }
  
  .bsa-chart th,
  .bsa-chart td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  
  .bsa-chart th {
    background-color: #1a5f7a;
    color: white;
  }
  
  .bsa-chart tr:last-child td {
    border-bottom: none;
  }
  
  .bsa-chart td:last-child,
  .bsa-chart th:last-child {
    border-right: none;
  }
  
  .conversion-link img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }