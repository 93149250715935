.header {
  background-color: #1a5f7a;
  padding: 20px 0;
  text-align: center;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-title {
  margin: 0 0 20px 0;
  font-size: 24px;
}

.header-title a {
  color: white;
  text-decoration: none;
}

.header-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.header-nav a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-size: 16px;
  padding: 10px 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: transparent;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.dropdown-arrow {
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
}

.menu-toggle {
  display: none;
}

@media screen and (max-width: 768px) {
  .header-content {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }

  .header-title {
    margin: 0;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }

  .menu-toggle-icon {
    display: block;
    width: 25px;
    height: 3px;
    background-color: white;
    position: relative;
  }

  .menu-toggle-icon::before,
  .menu-toggle-icon::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: all 0.3s ease;
  }

  .menu-toggle-icon::before {
    top: -8px;
  }

  .menu-toggle-icon::after {
    bottom: -8px;
  }

  .header-nav {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: left;
    padding: 10px 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #1a5f7a;
  }

  .header-nav.open {
    display: flex;
  }

  .header-nav a {
    margin: 5px 20px;
  }

  .dropdown {
    width: 100%;
  }

  .dropbtn {
    width: 100%;
    text-align: left;
    padding: 10px 20px;
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
    max-height: none;
    width: 100%;
  }

  .dropdown-content a {
    padding: 10px 30px;
  }
}